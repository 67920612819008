<template>
    <div style="padding:20px;">
        <el-form :inline="true" ref="form" :model="form" class="demo-form-inline">
            <el-form-item label="关键词">
                <el-input v-model="form.name" placeholder="请输入关键词"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
            </el-form-item>
        </el-form>

        <el-button type="primary" icon="el-icon-plus" @click="handleKeywordsEvent('add')">添加</el-button>

        <div class="table-box">
            <el-table
                :data="tableData"
                border
                style="width: 100%">
                <el-table-column
                type="index"
                label="序号"
                align="center"
                width="100">
                </el-table-column>
                <el-table-column
                prop="keyword"
                label="关键词"
                align="center">
                </el-table-column>
                <el-table-column
                label="等级"
                align="center">
                    <template slot-scope="scope">
                        <el-tag type="danger" v-if="scope.row.level == 3">重度</el-tag>
                        <el-tag type="warning" v-if="scope.row.level == 2">中度</el-tag>
                        <el-tag type="info" v-if="scope.row.level == 1">轻度</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                label="是否启用"
                align="center">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.state == 0">启用</el-tag>
                        <el-tag type="danger" v-if="scope.row.state == 1">停止</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                label="时间"
                align="center">
                    <template slot-scope="scope">
                        {{scope.row.createDate | date}}
                    </template>
                </el-table-column>
                <el-table-column
                label="操作"
                align="center">
                <template slot-scope="scope">
                    <el-button type="text" size="small" icon="el-icon-edit" @click="handleKeywordsEvent('edit', scope.row)">编辑</el-button>
                    <el-button type="text" size="small" icon="el-icon-delete" @click="handleKeywordsEvent('remove', scope.row)">删除</el-button>
                </template>
                </el-table-column>
            </el-table>
        </div>

        <el-pagination
        class="pagebox"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-size="form.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total">
        </el-pagination>

        <addKeyword ref="addKeywordDialog"></addKeyword>
    </div>
</template>
<script>
import addKeyword from './components/addKeyword.vue'
export default {
    data() {
        return {
            form: {
                name: '',
                pageNum: 1,
                pageSize: 10
            },
            tableData: [],
            total: 0
        }
    },
    components: {
        addKeyword
    },
    created(){
        this.onLoadList();
    },
    methods: {
        onLoadList(){
            this.$store.dispatch('manage/public', {
                query: this.form,
                requestInterfaceName: 'getKeywordList'
            }).then(res=>{
                if(res.code == 0) {
                    this.tableData = res.data;
                    this.total = res.total;
                }
            })
        },
        onSubmit() {
            this.onLoadList();
        },
        handleCurrentChange(val){
            this.form.pageNum = val;
            this.onLoadList()
        },
        // 按钮操作
        handleKeywordsEvent(type, row) {
            switch(type) {
                case 'add':
                    this.$nextTick(()=>{
                        this.$refs.addKeywordDialog.onInit(type)
                    })
                break;

                case 'edit':
                    this.$nextTick(()=>{
                        this.$refs.addKeywordDialog.onInit(type, row)
                    })
                break;

                case 'remove':
                    this.$confirm('是否确认删除当前关键词', "警告", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }).then(() => {
                        this.$store.dispatch('manage/public', {
                            query: row.id,
                            requestInterfaceName: 'removeKeywordList'
                        }).then(res=>{
                            if(res.code == 0) {
                                this.$message.success('删除成功');
                                this.onLoadList();
                                this.form.pageNum = 1;
                            } else {
                                this.$message.error(res.message);
                            }
                        })
                    })
                break;
            }
        }
    }
}
</script>
<style lang="less" scoped>
.table-box {
    margin: 20px 0;
}
</style>