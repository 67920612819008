<template>
    <el-dialog
        :title="mode == 'add' ? '新增' : '编辑'"
        :visible.sync="isDialog"
        :close-on-click-modal="false">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="关键词" prop="keyword">
                <el-input v-model="ruleForm.keyword" :clearable="true" placeholder="请输入关键词" :disabled="mode!='add'"></el-input>
            </el-form-item>
            <el-form-item label="等级" prop="level">
                <el-select v-model="ruleForm.level" placeholder="请选择等级">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="是否启用">
                <el-radio-group v-model="ruleForm.state" :disabled="mode!='add'">
                    <el-radio :label="0">启用</el-radio>
                    <el-radio :label="1">停止</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save">保存</el-button>
            <el-button @click="isDialog = false">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    data() {
        return {
            isDialog: false,
            mode: 'add',
            ruleForm: {
                keyword: '',
                level: 1,
                state: 0
            },
            rules: {
                keyword: [
                    { required: true, message: '请输入关键词', trigger: 'blur' }
                ],
                level: [
                    { required: true, message: '请选择等级', trigger: 'change' }
                ]
            },
            options: [{
                value: 1,
                label: '轻度'
            },{
                value: 2,
                label: '中度'
            },{
                value: 3,
                label: '重度'
            }]
        };
    },
    methods: {
        onInit(type, row){
            this.isDialog = true;
            this.mode = type;
            if(type == 'add') {
                this.ruleForm= {
                    keyword: '',
                    level: 1,
                    state: 0
                }
            } else {
                this.ruleForm= {
                    id: row.id,
                    keyword: row.keyword,
                    level: row.level,
                    state: row.state
                }
            }

            this.$refs['ruleForm'] ? this.$refs['ruleForm'].resetFields() : '';
        },
        // 保存
        save(){
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    this.$store.dispatch('manage/public', {
                        query: this.ruleForm,
                        requestInterfaceName: this.mode == 'add' ? 'addKeywordList' : 'editKeywordList'
                    }).then(res=>{
                        if(res.code == 0) {
                            this.$message.success(this.mode == 'add' ? '添加成功' : '修改成功');
                            this.$parent.onLoadList();
                        } else {
                            this.$message.error(res.message)
                        }
                        this.closeEvent();
                    })
                }
            })
        },
        closeEvent() {
            this.isDialog = false;
            this.$refs['ruleForm'].resetFields();
        }
    }
};
</script>